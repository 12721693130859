import { getCookies, removeCookies, removeTokenCookies } from './cookie'
import { queryParamsURL } from './queryParamsURL'

const { REACT_APP_ADMIN_URL } = process.env

const ISOCRHONE_MASS_UPLOAD_URL =
  '/dashboard/mass-upload?type=ISOCHRONE_LOCATION_V2'

const QUADRANT_MASS_UPLOAD_URL =
  '/dashboard/mass-upload?type=QUADRANT_ISOCHRONE'

const KITCHEN_MASS_UPLOAD_URL = '/dashboard/mass-upload?type=KITCHEN_ISOCHRONE'

export const navigateToAdmin = () => {
  window.location.href = `${REACT_APP_ADMIN_URL}`
}

export const navigateToIsochroneMassUpload = () => {
  window.location.href = `${REACT_APP_ADMIN_URL}${ISOCRHONE_MASS_UPLOAD_URL}`
}

export const navigateToQuadrantMassUpload = (megahubLocationId: number) => {
  window.location.href = `${REACT_APP_ADMIN_URL}${QUADRANT_MASS_UPLOAD_URL}&locationId=${megahubLocationId}`
}

export const navigateToAdminLocation = () => {
  setTimeout(() => {
    const { id } = queryParamsURL.get()
    const referer = getCookies('_ty')
    const showPopup =
      referer === 'edit' ? `&showEditPopup=${id}` : `&addIsochrone=true`

    removeTokenCookies()
    removeCookies('_ty')
    window.location.href = `${REACT_APP_ADMIN_URL}/dashboard/location?type=hub${showPopup}`
  }, 1500)
}

export const navigateToKitchenMassUpload = (hubLocationId: string) => {
  window.location.href = `${REACT_APP_ADMIN_URL}${KITCHEN_MASS_UPLOAD_URL}&locationId=${hubLocationId}`
}
