import './trackingOrder.css'
import 'leaflet-rotate'
import TrackingMap from 'components/TrackingMap'
import { useMemo } from 'react'

import { useNavigate } from 'react-router-dom'
import useWebViewSession from 'components/WebViewSession/useWebViewSession'
import FlyMapTo from 'components/TrackingMap/FlyMapTo'

import { RestrictedWebview } from 'components/WebViewSession'
import { webviewPostMessage } from 'utils/postMessage'
import { ActionPostMessageNativeAppEnum } from 'constants/postMessage'
import { useLiveTrackingCrewStore } from 'store/liveTrackingCrew'
import Content from './Content'

/**
 * @default Monas Lat Long
 */
const placeholderPosition: [number, number] = [-6.175392, 106.827153]

const LiveTrackingCrew = () => {
  const navigate = useNavigate()
  const { webviewSessionProps } = useWebViewSession()
  const { order } = useLiveTrackingCrewStore()

  const { Authorization } = webviewSessionProps.nativeHeaders || {}

  const location = useMemo(
    () =>
      order
        ? ([order.lat, order.lon] as [number, number])
        : placeholderPosition,
    [order]
  )

  if (!Authorization) {
    return (
      <RestrictedWebview
        goBack={() => {
          if (webviewSessionProps.isWebview) {
            webviewPostMessage({
              action: ActionPostMessageNativeAppEnum.WEBVIEW_NAVIGATE_BACK,
            })
          } else {
            navigate(-1)
          }
        }}
      />
    )
  }

  return (
    <>
      <TrackingMap
        key={location[0].toString()}
        apiKey={process.env.REACT_APP_MAPS_KEY_LIVE_TRACKING_CREW || ''}
        className="live-tracking-order"
        center={location}
        zoom={19}
        rotate
        touchRotate
        shiftKeyRotate
        touchGestures
      >
        <FlyMapTo position={location} zoom={19} />
        <Content />
      </TrackingMap>
    </>
  )
}

export default LiveTrackingCrew
