import { toastWebview } from 'components/ToastWebview'
import {
  getRadiusLocationAPI,
  postGetRouteInternalAPI,
  PostGetRouteRequest,
} from 'utils/apiList/tracking'
import { polylineDecode } from 'utils/polylineDecode'
import { queryParamsURL } from 'utils/queryParamsURL'

type DriverPostion = {
  driver_id: number
  driver_status: string
  driver_name: string
  lat: number
  lon: number
  timestamp: number
}

type Order = {
  lat: number
  lon: number
}

export type LiveTrackingCrewSlice = {
  isAuthorized: boolean
  isLoading: boolean
  isCompleted: boolean
  isError: boolean
  driverPosition: DriverPostion | null
  driverRoute: [number, number][]
  order: Order | null
  radius: number | null
  setIsLoading: (isLoading: boolean) => void
  setIsError: (isError: boolean) => void
  setDriverPosition: (
    payload: DriverPostion,
    callback?: (payload: {
      isAuthorized: boolean
      driverHasAdded: boolean
      route: [number, number][]
      orderPosition?: [number, number]
    }) => void
  ) => void
  setOrder: (payload: Order, callback?: () => void) => void
  setRoute: (payload: [number, number][]) => void
  fetchPostGetRouteInternal: (
    payload: PostGetRouteRequest
  ) => Promise<[number, number][]>
  fetchGetRadiusLocation: () => Promise<void>
}

const qs = queryParamsURL.get()

export const createLiveTrackingCrewSlice: StoreSlice<LiveTrackingCrewSlice> = (
  set,
  get
) => ({
  isAuthorized: true,
  isLoading: true,
  isError: false,
  isCompleted: false,
  order:
    qs?.destination_lat && qs?.destination_long
      ? {
          lat: +qs.destination_lat,
          lon: +qs.destination_long,
        }
      : null,
  driverPosition: null,
  driverRoute: [],
  radius: null,
  setIsLoading: (isLoading) => {
    set((state) => {
      state.isLoading = isLoading
    })
  },
  setIsError: (isError) => {
    set((state) => {
      state.isError = isError
    })
  },
  setDriverPosition: (payload, callback) => {
    const { isAuthorized, order, driverPosition, driverRoute } = get()

    set((state) => {
      state.driverPosition = payload
      state.isError = false
    })

    if (callback) {
      callback({
        isAuthorized,
        driverHasAdded: !!driverPosition,
        route: driverRoute,
        orderPosition: order ? [order.lat, order.lon] : undefined,
      })
    }
  },
  setOrder: (payload, callback) => {
    set((state) => {
      state.order = payload
    })

    if (callback) callback()
  },
  setRoute: (payload: [number, number][]) => {
    set((state) => {
      state.driverRoute = payload
    })
  },
  fetchPostGetRouteInternal: async (payload) => {
    try {
      const response = await postGetRouteInternalAPI(payload)

      const shapeEncoded = response.data.data.trip.detail?.[0].shape
      if (shapeEncoded) {
        const route = polylineDecode(shapeEncoded)

        return route
      }

      return []
    } catch (err) {
      const error = (err as { message: string })?.message || ''
      if (error.includes('401')) {
        set((state) => {
          state.isAuthorized = false
        })
      }
      toastWebview({ message: error })
      return []
    }
  },
  fetchGetRadiusLocation: async () => {
    try {
      const response = await getRadiusLocationAPI()

      set((state) => {
        state.radius = response.data.radius
      })
    } catch (err) {
      const error = (err as { message: string })?.message || ''
      if (error.includes('401')) {
        set((state) => {
          state.isAuthorized = false
        })
      }
      toastWebview({ message: error })
    }
  },
})
