import { Wrapper } from '@googlemaps/react-wrapper'
import { MapContainer, MapContainerProps } from 'react-leaflet'
import TileLayer from './TileLayer'

export type PoolMapType = {
  children: React.ReactNode
  mqttUsername?: string
  mqttPassword?: string
} & MapContainerProps

const PoolMap = ({
  children,
  zoom = 19,
  dragging = true,
  ...props
}: PoolMapType) => (
  <Wrapper apiKey={process.env.REACT_APP_MAPS_KEY_POOLING_MAP as string}>
    <MapContainer
      zoom={zoom}
      dragging={dragging}
      style={{ width: '100%', height: '100vh' }}
      {...props}
    >
      <TileLayer />
      {children}
    </MapContainer>
  </Wrapper>
)

export default PoolMap
