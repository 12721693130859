import { LatLngExpression } from 'leaflet'
import { useEffect, useState } from 'react'
import { Circle } from 'react-leaflet'

interface PulsingCircleProps {
  center: LatLngExpression
  maxRadius: number
  duration?: number
}

const PulsingCircle: React.FC<PulsingCircleProps> = ({
  center,
  maxRadius,
  duration = 2000,
}) => {
  const [radius, setRadius] = useState(0)

  useEffect(() => {
    let animationFrame: number
    const animatePulse = () => {
      const start = performance.now()

      const animate = (time: number) => {
        const elapsed = time - start
        const progress = (elapsed % duration) / duration // Mengulang setiap durasi selesai
        const currentRadius = maxRadius * progress // Radius sesuai progress animasi

        setRadius(currentRadius)

        // Lanjutkan animasi dengan requestAnimationFrame
        animationFrame = requestAnimationFrame(animate)
      }

      animationFrame = requestAnimationFrame(animate)
    }

    animatePulse()

    return () => cancelAnimationFrame(animationFrame)
  }, [maxRadius, duration])

  return (
    <>
      <Circle
        center={center}
        radius={maxRadius}
        pathOptions={{
          color: '#246EE5',
          fillColor: '#246EE5',
          fillOpacity: 0.1,
          weight: 1,
        }}
      />
      <Circle
        center={center}
        radius={radius}
        pathOptions={{
          color: '#246EE5',
          fillColor: '#246EE5',
          weight: 0,
        }}
      />
    </>
  )
}

export default PulsingCircle
